export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const USERS_LOADED = "USERS_LOADED";
export const USERS_ERROR = "USERS_ERROR";
export const USER_LOCATION = "USER_LOCATION";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const ACCOUNT_DELETE = "ACCOUNT_DELETE";
export const GET_REVIEW = "GET_REVIEW";
export const REVIEW_ERROR = "REVIEW_ERROR";
export const ADD_COMMENT = "ADD_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";
export const CHANGE_NAVBAR_VALUE = "CHANGE_NAVBAR_VALUE";
export const IMAGES_UPLOAD_SUCCESS = "IMAGES_UPLOAD_SUCCESS";
export const IMAGES_UPLOAD_FAIL = "IMAGES_UPLOAD_FAIL";
export const IMAGES_UPLOADING = "IMAGES_UPLOADING";
export const CLEAR_IMAGES = "CLEAR_IMAGES";
export const IMAGES_DELETE_SUCCESS = "IMAGES_DELETE_SUCCESS";
export const IMAGES_DELETE_FAIL = "IMAGES_DELETE_FAIL";
export const NUMBER_VERIFY_FAIL = "NUMBER_VERIFY_FAIL";
export const NUMBER_VERIFY_SUCCESS = "NUMBER_VERIFY_SUCCESS";
export const GET_IMAGES = "GET_IMAGES";
export const INFO_UPDATE_SUCCESS = "INFO_UPDATE_SUCCESS";
export const INFO_UPDATE_FAIL = "INFO_UPDATE_SUCCESS";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const CLEAR_SEARCH = "CLEAR_SEARCH";
export const SAVE_CONFIRMATION_EMAIL = "SAVE_CONFIRMATION_EMAIL";
export const CREATE_STRIPE_ACCOUNT = "CREATE_STRIPE_ACCOUNT";
export const CREATE_STRIPE_ACCOUNT_SUCCESS = "CREATE_STRIPE_ACCOUNT_SUCCESS";
export const CREATE_STRIPE_ACCOUNT_FAIL = "CREATE_STRIPE_ACCOUNT_FAIL";
export const CREATE_STRIPE_CUSTOMER = "CREATE_STRIPE_CUSTOMER";
export const CREATE_STRIPE_CUSTOMER_SUCCESS = "CREATE_STRIPE_ACCOUNT_SUCCESS";
export const CREATE_STRIPE_CUSTOMER_FAIL = "CREATE_STRIPE_ACCOUNT_FAIL";
export const PAY_SUCCESS = "PAY_SUCCESS";
export const PAY_FAIL = "PAY_FAIL";
export const SERVICE_SUCCESS = "SERVICE_SUCCESS";
export const SERVICE_FAIL = "SERVICE_FAIL";
export const ADD_TO_CART = "ADD_TO_CART";
export const CLEAR_CART = "CLEAR_CART";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const SUB_QUANTITY = "SUB_QUANTITY";
export const ADD_QUANTITY = "ADD_QUANTITY";
export const ADD_SHIPPING = "ADD_SHIPPING";
export const INITIAL_SERVICES = "INITIAL_SERVICES";
export const ADD_BOOK = "ADD_BOOK";
export const ADD_BOOK_SUCCESS = "ADD_BOOK_SUCCESS";
export const ADD_BOOK_FAIL = "ADD_BOOK_FAIL";
export const GET_BOOK = "GET_BOOK";
export const CHANGE_STATE_BOOKING = "CHANGE_STATE_BOOKING";
export const CHANGE_STATE_BOOKING_SUCCESS = "CHANGE_STATE_BOOKING_SUCCESS";
export const CHANGE_STATE_BOOKING_FAIL = "CHANGE_STATE_BOOKING_FAIL";
export const COMPLETE_SERVICE = "COMPLETE_SERVICE";
export const COMPLETE_SERVICE_SUCCESS = "COMPLETE_SERVICE_SUCCESS";
export const COMPLETE_SERVICE_FAIL = "COMPLETE_SERVICE_FAIL";
export const CLEAR_BOOK = "CLEAR_BOOK";
export const ADD_CARD = "ADD_CARD";
export const ADD_CARD_SUCCESS = "ADD_CARD_SUCCESS";
export const ADD_CARD_FAIL = "ADD_CARD_FAIL";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const SEND_EMAIL_HELP_CENTER_SUCCESS = "SEND_EMAIL_HELP_CENTER_SUCCES";
export const ADD_BOOKINGS = "ADD_BOOKINGS";
export const SAVE_USER_NUMBER_SUCCESS = "SAVE_USER_NUMBER_SUCCESS";
