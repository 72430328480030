import React from "react";
import ReactMarkdown from "markdown-to-jsx";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const styles = theme => ({
  listItem: {
    marginTop: theme.spacing(1)
  }
});

const options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: "h1"
      }
    },
    h2: { component: Typography, props: { gutterBottom: true, variant: "h3" } },
    h3: { component: Typography, props: { gutterBottom: true, variant: "h4" } },
    h4: {
      component: Typography,
      props: { gutterBottom: true, variant: "caption", paragraph: true }
    },
    p: {
      component: Typography,
      props: { paragraph: true, variant: "body1" }
    },
    a: { component: Link },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <li className={classes.listItem}>
          <Typography component="span" {...props} />
        </li>
      ))
    }
  }
};

export default function Markdown(props) {
  return <ReactMarkdown options={options} {...props} />;
}
